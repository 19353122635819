import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/ekg/Banner.webp';
import Img1 from '../assets/images/ourservices/ekg/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const EKG = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
    <title> EKG Services | Madison Primary Care </title>
    <meta name="description" content="Stay on top of your heart health with EKG services at Madison Primary Care. We provide precise diagnostics and proactive cardiac care in a patient-focused environment."/>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <h1 className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Electrocardiogram 
          </h1>
          <h2 className='fw-400 lh-sm text-ss-green banner-title2'>
          EKG at MPC
          </h2>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <h2 className='fs-ss-30 fw-600 fw-600 lh-sm'>
            EKG Service <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </h2>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Always call 911 or visit the nearest emergency room for any serious or life threatening symptoms. We encourage all our patients to be familiar with the usual and unusual signs and symptoms of a heart attack, and be knowledgeable on when to report or seek emergency medical attention.  
            <div className="py-2" />
            At Madison Primary Care, we understand the importance of monitoring your heart health, and offer some screening and diagnostic tools. 
            </div>
            <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-4 text-start">
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Electrocardiogram (EKG) Testing <br/>
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Routine Heart Health Screening <br/>
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Assessing Risk Factors  <br/>
            </div>

            <div className="col-11 col-md-4 text-start">
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Facilitating Diagnostic Testing   <br/>
            <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Referrals for Speciality Evaluation    <br/>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
            <div className='pt-2'/>
            <h2 className='fs-ss-35 fw-700 lh-sm '>
              EKG Services
        </h2>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        By measuring the heart's rhythm and detecting abnormalities, EKGs help healthcare professionals identify potential heart conditions. This non-invasive procedure involves placing electrodes on the chest, arms, and legs to record the heart's electrical signals, providing valuable insights into cardiac health.
        </div>
        </div>
        </div>
        <div className="col-7 col-lg-5 text-center px-md-5 mt-0 mt-md-5 mt-lg-0">
        <img className='px-md-4 w-75' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <h2 className='fs-ss-30 fw-600 fw-600 lh-sm'>
              EKG Testing
            </h2>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            In addition to providing EKG testing, our clinic offers comprehensive services to support your heart health journey. Whether you need routine screenings, preoperative assessments, or ongoing monitoring for existing conditions, our team provides personalized care with a focus on early detection and prevention. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <h2 className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </h2>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="What is an EKG, and how does it work?  "
          Para="It is a non-invasive test that records the electrical activity of your heart. It involves placing electrodes on your chest, arms, and legs, which detect and measure the electrical signals produced by your heart's contractions. The resulting waveform, an EKG or ECG, provides valuable information about your heart's rhythm and function. "
          />

          <FAQs
          Title="Why might I need an EKG? "
          Para="Your healthcare provider may recommend an EKG for various reasons, including screening, establishing a baseline, or evaluating symptoms."
          />

          <FAQs
          Title="Is an EKG painful or invasive?  "
          Para="No, an EKG is a painless and non-invasive procedure. It involves attaching electrodes to your skin, which may cause a mild sensation when removed but should not cause discomfort during the test. If you have any concerns or anxiety about the procedure, our team will ensure you feel comfortable and informed before proceeding. "
          />

          <FAQs
          Title="How long does an EKG test take?  "
          Para="An EKG typically takes only a few minutes, and recording usually lasts less than 10 minutes, when you will be asked to lie still while the electrodes collect data. Once the test is done, your healthcare provider will review the findings or next steps. "
          />

          <FAQs
          Title="Can I get the results of my EKG immediately?  "
          Para="In many cases, the results of an EKG can be reviewed immediately after the test is completed. Your healthcare provider will analyze the waveform patterns and provide preliminary findings during your appointment. However, further interpretation or additional testing may be necessary, depending on the results and your health status. "
          />

          <FAQs
          Title="Can an EKG detect all heart problems?  "
          Para="While an EKG is a valuable diagnostic tool for detecting certain heart conditions, it may not identify every type of heart problem. Some cardiac issues may require additional tests or referrals for a comprehensive evaluation. "
          />

          <FAQs
          Title="How often should I undergo an EKG test?  "
          Para="The frequency of EKG testing depends on your health status, risk factors, and medical history. Your healthcare provider will determine the appropriate schedule for testing based on factors such as age, presence of symptoms, and underlying medical conditions. Following your provider's recommendations for regular heart health assessments is essential.  "
          />
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default EKG;
