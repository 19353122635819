import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const InquiryForm = () => {
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(); // Reference for reCAPTCHA
  const refform = useRef();

  // State for all input fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+1"); // Default country code
  const [message, setMessage] = useState("");

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Update the token on reCAPTCHA completion
  };

  const sendForm = (e) => {
    e.preventDefault();

    // Validate name
    if (!name) {
      toast.error("Please enter your name.", { position: "bottom-left" });
      return;
    }

    // Validate email
    if (!email) {
      toast.error("Please enter your email.", { position: "bottom-left" });
      return;
    }

    // Validate phone number
    if (!phone || phone.length < 5) {
      toast.error("Please enter a valid phone number.", { position: "bottom-left" });
      return;
    }

    // Validate message
    if (!message) {
      toast.error("Please enter a message.", { position: "bottom-left" });
      return;
    }

    // Validate CAPTCHA
    if (!captchaToken) {
      toast.error(
        <>
          <div className="text-start">
            Google reCAPTCHA verification failed, please try again later.
          </div>
        </>,
        { position: "bottom-left" }
      );
      return;
    }

    // Send the form if all fields are valid and CAPTCHA is completed
    emailjs
      .sendForm(
        "madison_primary_care", // Replace with your EmailJS service ID
        "madison_primary_care", // Replace with your EmailJS template ID
        refform.current, // Use form reference
        "XbLEIPt9lCIYSmcuM" // Replace with your EmailJS public key
      )
      .then((res) => {
        console.log(res);
        toast.success(
          <>
            <div className="text-start">
              Thank you for your inquiry. We will get back to you shortly!
            </div>
          </>,
          { position: "bottom-left" }
        );
        // Reset reCAPTCHA
        captchaRef.current.reset();
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occurred while submitting the form.");
      });

    // Reset all fields except phone default
    setName("");
    setEmail("");
    setPhone("+1");
    setMessage("");
    setCaptchaToken(null); // Reset CAPTCHA token
  };

  return (
    <>
      <ToastContainer />
      <section className="container-fluid px-4 px-lg-5 mt-lg-4 bg-grey-2 rounded-5 shadow-ss-1">
        <div className="row justify-content-center align-items-center py-5">
          <div className="col-12">
            <div className="">
              <h2 className="fs-ss-30 fw-600 lh-sm text-center text-ss-primary">
                DROP US A MESSAGE
              </h2>
              <div className="text-center col-12">
                <form
                  ref={refform}
                  onSubmit={sendForm}
                  id="contactForm"
                  method="POST"
                  action="#"
                  className="px-lg-4"
                >
                  <div className="form-group">
                    <label className="invisible font-pri fw-600" htmlFor="name">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control py-2"
                      id="name"
                      name="name"
                      placeholder="Name*"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label className="invisible font-pri fw-600" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control py-2"
                      id="email"
                      name="email"
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label
                      className="invisible font-pri fw-600"
                      htmlFor="phone_number"
                    >
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"us"}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                      className="form-control p-0"
                      placeholder="" // No placeholder
                    />
                    {/* Hidden input to ensure phone number with country code is sent */}
                    <input
                      type="hidden"
                      id="phone_number"
                      name="phone_number"
                      value={phone}
                    />
                  </div>
                  <div className="form-group">
                    <label className="invisible font-pri fw-600" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      className="col-pri form-control py-2"
                      id="message"
                      name="message"
                      rows="2"
                      placeholder="Message*"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="pt-4">
                    <ReCAPTCHA
                      sitekey="6LczmagpAAAAAMChSAHAJYbF_6n7yE0jaSH-ylfZ"
                      onChange={handleCaptchaChange}
                      ref={captchaRef} // Attach reCAPTCHA reference
                    />
                    <button className="mt-4 text-center nav-link fw-500 shadow-ss-1 btn-black border-0 rounded-3 border-0 text-white fs-ss-16 px-3 py-1 bg-hover-green">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InquiryForm;
