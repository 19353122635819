import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/sick-visits-and-urgent-test/Banner.webp';
import Img1 from '../assets/images/ourservices/sick-visits-and-urgent-test/Img.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const SickVisitsUrgentCare = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
    <title> Urgent Care and Sick Visits at Madison Primary Care </title>
    <meta name="description" content="Receive prompt urgent care and sick visit services at Madison Primary Care, ensuring quick, effective treatment when you need it most."/>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <h1 className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Sick Visits and Urgent Care
          </h1>
          <h2 className='fw-400 lh-sm text-ss-green banner-title2'>
          Sick Visits and Urgent Care at MPC
          </h2>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <h2 className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Sick Visits & Urgent Care <br className='' /> 
            <span className="text-ss-primary"> Madison Primary Care </span>
            </h2>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            MPC is not an urgent care clinic with walk-in services. We make every effort to evaluate and treat our established patients for their urgent care needs. Calling early in the day or utilizing the Patient Portal to schedule an appointment will help you secure your same day or next day appointment.  
            <div className="py-2" />
            We request all patients to contact MPC before going to an Urgent Care facility, but never delay in calling 911 or visiting the nearest emergency room for any serious or life threatening symptoms.  
            <div className="py-2" />
            We encourage all our patients to be familiar with the usual and unusual signs and symptoms of a heart attack, stroke, and other life threatening emergencies. We recommend patients and their families take basic life saving courses and be knowledgeable on when to report or seek emergency medical attention.  
            <div className="py-2" />
            Illnesses and injuries often occur unexpectedly, disrupting our daily lives. At Madison Primary Care, we understand the urgency of these situations, so we offer sick visits and urgent care services to address your health needs promptly. Our compassionate team is dedicated to providing timely diagnosis and treatment for various illnesses and injuries, helping you get back on your feet as quickly as possible. 
            </div>
            <div className="row justify-content-start pt-3 fs-ss-16 fw-500 lh-para">
            <div className="col-11 col-md-5 text-start">
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Same-Day Appointments <br/>
              {/* <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Comprehensive Evaluation <br/> */}
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Treatment of Acute Illnesses <br/>
            </div>

            <div className="col-11 col-md-5 text-start">
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Management of Minor Injuries <br/>
              <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Medication Management and Refills <br/>
            </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg">
      <div className="row justify-content-center gy-4 align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <img className='w-logo' src={HeartLogo} alt="" />
        <div className='pt-2'/>
        <h2 className='fs-ss-35 fw-700 lh-sm '>
        Benefits
        </h2>
        <div className='pt-3'/>
        <div className="">
        <div className="pt-2 fw-600">
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Same-Day Appointments
        <div className='fs-ss-16 fw-400'>
        We prioritize your health by offering same-day appointments for urgent medical concerns. 
        </div>
        </div>

        <div className="pt-2 fw-600">
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Comprehensive Evaluation  <br/>
        <div className='fs-ss-16 fw-400'>
        Our healthcare providers conduct thorough assessments to diagnose and address your symptoms. 
        </div>
        </div>

        <div className="pt-2 fw-600">
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Treatment of Acute Illnesses  <br/>
        <div className='fs-ss-16 fw-400'>
        We provide effective treatment options to alleviate your symptoms and promote healing. 
        </div>
        </div>

        <div className="pt-2 fw-600">
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Management of Minor Injuries  <br/>
        <div className='fs-ss-16 fw-400'>
        Whether it's a sprain, strain, or minor laceration, we offer expert care to manage your injuries and prevent complications. 
        </div>
        </div>

        <div className="pt-2 fw-600">
        <CircleFill className='text-ss-primary fs-ss-7 me-3'/> Medication Management  <br/>
        <div className='fs-ss-16 fw-400'>
        Our team prescribes medications as needed to help manage your symptoms and support your recovery process. 
        </div>
        </div>
        </div>
        </div>
        </div>
        <div className="col-6 col-lg-5 text-center px-md-5">
        <img className='px-md-5 w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>

    <section className='pt-4 pt-md-5'/>

    <MiddleBanner/>

    <section className='pt-4 pt-md-5'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-md-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <h2 className='fs-ss-30 fw-600 fw-600 lh-sm'>
            Get the Care <br className='' /> 
            <span className="text-ss-primary"> You Need When You Need It </span>
            </h2>
            <div className='pt-3'/>
            <div className='fs-ss-16 fw-400 lh-para text-start'>
            Don't let illness or injury disrupt your life. Trust Madison Primary Care for quick sick visits and urgent care. We offer same-day appointments and compassionate services. Schedule your appointment today and get the care you need to feel better soon. 
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <section id='website-responsive' className="container-lg text-ss-secondary">
    <h2 className='fs-ss-30 fw-600 fw-600 lh-sm text-center pb-4'>
      FAQS
      </h2>
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <FAQs
          Title="When should I seek urgent care? "
          Para="You should seek urgent care for medical issues requiring prompt attention but not life-threatening. Examples include fever, minor injuries, earaches, and urinary tract infections. "
          />

          <FAQs
          Title="What to bring for urgent care appointments?  "
          Para="It's helpful to bring your identification, insurance card, and a list of current medications. If applicable, bring any relevant medical records or information about your symptoms. "
          />

          <FAQs
          Title="Can I receive treatment for chronic conditions during an urgent care visit? "
          Para="Urgent care appointments are focused visits added to our Provider's schedule. They typically address one problem, illness or issue and are not designed to be combined with your routine wellness or chronic condition follow up visits."
          />

          {/* <FAQs
          Title="Does insurance cover urgent care services? "
          Para="Health insurance programs often cover urgent care visits, though your plan may differ. It's wise to speak with your insurance company to learn more about your benefits and related expenses.   "
          /> */}

          <FAQs
          Title="How long does a typical urgent care visit last?  "
          Para="The duration of an urgent care visit varies depending on the complexity of your condition and the services required. On average, most visits last between 30 minutes to an hour.  "
          />

          <FAQs
          Title="Can I walk in for an urgent care appointment or schedule in advance?  "
          Para="MPC is not an urgent care clinic with walk-in services, all appointments must be scheduled. We make every effort to evaluate and treat our established patients for their urgent care needs. Calling early in the day or utilizing the Patient Portal 24 hours a day to schedule an appointment will help you secure your same day or next day appointment."
          />

          <FAQs
          Title="What types of minor injuries can be treated in Madison Primary Care?  "
          Para="Urgent care centers can treat minor injuries, including sprains, strains, minor cuts, and minor illnesses. If you're unsure whether your injury requires urgent care, it's best to call ahead for guidance. "
          />
        </div>
      </div>
    </section>

    <section className='pt-4 pt-md-5'/>

    <Footer/>
    </>
  );
}

export default SickVisitsUrgentCare;
