import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import BannerImage from '../assets/images/ourservices/chronic-disease-management/Banner.webp';
import Img1 from '../assets/images/ourservices/primary-care/Img2.webp';
import {Link} from "react-router-dom";
import HeartLogo from '../assets/images/aboutus/heart.webp';
import MiddleBanner from '../components/common/MiddleBanner';
import FAQs from '../components/FAQs';
import { CircleFill } from 'react-bootstrap-icons';

const ChronicDiseaseManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
    <>
    <Helmet>
    <title> Chronic Disease Management at Madison Primary Care </title>
    <meta name="description" content="Madison Primary Care offers expert chronic disease management with personalized care plans and support for long-term wellness and improved quality of life."/>
    </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid common-banner py-md-5 shadow-ss-1 text-center" style={{backgroundImage:`url(${BannerImage})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 py-5 my-lg-5">
          <div className="pb-2">
          <div className='text-center'>
          <h1 className='fw-500 lh-sm text-white text-uppercase banner-title'>
          Chronic Disease Management
          </h1>
          <h2 className='fw-400 lh-sm text-ss-green banner-title2'>
          Chronic Disease Management at MPC
          </h2>
          </div>
          </div>
        </div>
      </div>
    </section>

    <section className="container-fluid py-5 bg-grey-2 text-center">
    <div className="row justify-content-center">
    <div className="col-11 col-md-9">
    <div className='d-lg-flex justify-content-center'>
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1' target='_blank'>
              Established Patients
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
          <a href="https://forms.gle/CQNacEvwJG4urSi28" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; New Patients &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
          <div className='px-2 py-3' />
          <div className='text-center'>
            <a href="https://12216.portal.athenahealth.com/" target="_blank" className='text-decoration-none py-2 fw-500 btn-black border-0 rounded-3 fs-ss-16 px-4 shadow-ss-1'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Patient Portal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
          </div>
    </div>
    </div>
    </div>
    </section>


    <section className='pt-4'/>

  <section id='website-responsive' className="container-lg">
      <div className="row justify-content-evenly align-items-center">
        <div className="col-11 col-lg-6 text-ss-secondary">
        <div className=''>
        <h2 className='fs-ss-35 fw-700 lh-sm '>
        Chronic Disease Management
        </h2>
        <div className='pt-3'/>
        <div className='fs-ss-16 fw-400 lh-para'>
        The defining features of primary care is continuity, coordination, and comprehensiveness which are well suited to care of chronic illness. 
        <div className="py-2" />
        A focal point in Primary Care is helping patients manage their chronic diseases to maintain and help improve their quality of life. At the same time, your Primary Care provider seeks to prevent or minimize the effects of chronic conditions. Through regularly scheduled visits, you and your Provider should remain connected, improving the coordination of care across specialties and healthcare delivery points. capacity. 
        </div>
        </div>
        </div>
        <div className="col-10 col-lg-5 text-center px-md-5">
        <img className='w-100' src={Img1} alt="" />
        </div>
      </div>
      </section>
      
  <section className='pt-md-4'/>

    <Footer/>
    </>
  );
}

export default ChronicDiseaseManagement;
